* {
    font-family: "Segoe UI Variable Text", "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif ;
}
.responsive {
    height: auto;
    width: 80%;
}
.mobileResponsive {
    height: auto;
    width: 40%;
}
.font {
    font-family: "Segoe UI Variable Text", "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif !important;
}
.HLP{ 
    margin-top: 70px;
}