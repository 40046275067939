  
    #header .pro-sidebar-inner {
    background-color: transparent;
    box-shadow: 1px 0.866px 2px 0.7px #0492C2
    }
    
    #header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
    font-size: 20px;
    padding: 0 20px;
    color: rgba(77, 36, 36, 0.521);
    font-weight: bold;
    }
 
    #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
    color: rgb(15, 5, 5);
    /* margin: 10px 0px; */
    font-weight: bold;
    
    }
    #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item:hover{
        background-color: #0492C2;
        color:#ffff;
        
    }
#header .pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
        position: relative;
        background-color:#ffff;
    }
 /* #header .pro-sidebar{
    width:350px;
 } */
 

