.imageforbg {
  /* Full height */
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 60px;
}

.textimage {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  margin-bottom: 5px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 115px;
  height: 38px;
}

.switch-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.switch-label {
  position: relative;
  display: block;
  height: inherit;
  font-size: 10px;
  text-transform: uppercase;
  background: #eceeef;
  border-radius: inherit;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
}

.switch-label:before,
.switch-label:after {
  position: absolute;
  top: 50%;
  margin-top: -.5em;
  line-height: 1;
  -webkit-transition: inherit;
  -moz-transition: inherit;
  -o-transition: inherit;
  transition: inherit;
}

.switch-label:before {
  content: attr(data-off);
  right: 11px;
  color: #aaaaaa;
  text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}

.switch-label:after {
  content: attr(data-on);
  left: 11px;
  color: #FFFFFF;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
  opacity: 0;
}

.switch-input:checked~.switch-label {
  background: #0088cc;
  border-color: #0088cc;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
}

.switch-input:checked~.switch-label:before {
  opacity: 0;
}

.switch-input:checked~.switch-label:after {
  opacity: 1;
}

.switch-handle {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 28px;
  height: 28px;
  background: linear-gradient(to bottom, #FFFFFF 40%, #f0f0f0);
  background-image: -webkit-linear-gradient(top, #FFFFFF 40%, #f0f0f0);
  border-radius: 100%;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}

.switch-handle:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -6px 0 0 -6px;
  width: 12px;
  height: 12px;
  background: linear-gradient(to bottom, #eeeeee, #FFFFFF);
  background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF);
  /* border-radius: 6px; */
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
}

.switch-input:checked~.switch-handle {
  left: 74px;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}

/* Transition
========================== */
.switch-label,
.switch-handle {
  transition: All 0.3s ease;
  -webkit-transition: All 0.3s ease;
  -moz-transition: All 0.3s ease;
  -o-transition: All 0.3s ease;
}

.hieghtforcard {
  height: 60% !important;

}

.contactusfont {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.registerPI .react-tel-input .form-control {
  border: none !important;
  border-radius: none;
  width: 90% !important;
  height: 20px !important;
  margin-left: 30px;

}

.react-tel-input>.form-control:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.form-floating>.form-control-plaintext:focus,
.form-floating>.form-control-plaintext:not(:placeholder-shown),
.form-floating>.form-control:focus,
.form-floating>.form-control:not(:placeholder-shown) {
  padding-top: 1.625rem !important;
  padding-bottom: .625rem !important;
}

.form-floating>.form-control,
.form-floating>.form-control-plaintext,
.form-floating>.form-select {
  height: calc(3.5rem + 2px) !important;
  line-height: 1.25 !important;
}

.form-floating>.form-control,
.form-floating>.form-control-plaintext {
  padding: 1rem 0.75rem !important;
}

.registerPI .react-tel-input .flag-dropdown {
  top: 62px !important;
  margin-left: auto;
  height: 15px;
  position: absolute;
  margin-left: 4px;
  border: none;
  background-color: none;
}

.registerPI .react-tel-input .selected-flag {
  outline: none !important;
  position: relative !important;
  /* height: 100% !important ; */
  padding: 0 0 0 11px !important;
  border-radius: 3px 0 0 3px !important;
  bottom: 35px !important;
}

.required {
  content: "*";
  color: red;
}

#patientgpsameadd {
  margin-top: 2px !important;
  margin-left: 26px;
}

@media only screen and (max-width: 600px) {
  #flexCheckChecked27{
    height: 1em !important;
  }
  #flexCheckChecked21{
    height: 1em !important;
  }

}

@media only screen and (max-width: 771px) {
  #margingp{
    margin-left: 12px !important;
  }
}

