/* .ObservationResult .table{
    border: none!important ;
   
}
.ObservationResult .table td, th, tr{
    border: none!important ;
} */


#Mainheading1{
    font-weight: 700 !important;
    width: 16% !important;
}
#Mainheading2{
    font-weight: 700 !important;
}
#Mainheading3{
    font-weight: 700 !important;
   
}
#Mainheading4{
    font-weight: 700 !important;
    width: 10% !important;
}
#Mainheading5{
    font-weight: 700 !important;
    width: 60% !important;
}


#hl7report{
    text-align: center !important;
    color: white !important;
}

#ObservationResultatag:hover{
    color: #fff !important;
}