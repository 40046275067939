.openimg{
    width: 37px;
    margin: 0% 0% 0% 96%;
    border-radius: 26px;
    background: #172f43!important;
    border: none !important;
    color: white;  
}
.card.hovercard .avatar {
    position: relative;
}
image-upload > input
{
    display: none;
 
}
label{
    color: #000;
}
.colorprofile{
    background-color: #172f43!important;
 }
.card.hovercard .avatar img {
    width: 100px;
    height: 100px;
    max-width: 100px;
    max-height: 100px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    /* border: 10px solid rgba(255,255,255,0.5); */
}
.card.hovercard .avatar {
    position: relative;
}
.rounded-circle{
    border-radius: 50%!important;
    border: 10px solid #fff!important;
    padding: 5% 5% 5% 5% !important;
}
.uploadbtn{
    margin-top: 2%;
    justify-content: center;
}

#tooltip {
    background: #333;
    color: white;
    font-weight: bold;
    padding: 4px 8px;
    font-size: 13px;
    border-radius: 4px;
}