.heading-section3 {
	background-color: #009FE3;
	border-radius: 50px;
}

.required:after {
	content: " *";
	color: red
}

.fa {
	cursor: pointer;
}

table {
	margin-top: 0.5%;
}


	#cardabovetable{
		border: 0px solid !important;
	}
	.col-lg-4{
		line-height: 1.3!important;
	}

	
	.fixed-Div {
		width: 100% !important;
		height: 1000px !important;
		overflow: auto !important;
		position: relative;
	}

	#topbar{
		width:100%;
		height:1000px;
		overflow:scroll;
		}

.btn-profile {
	background-color: #17a2b8;
	border: none;
	color: #FFF;
	padding: 6px 18px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 14px;
	margin: 4px 2px;
	cursor: pointer;
	font-weight: 400;
	cursor: pointer;
	text-transform: uppercase;
	font-weight: 500;
	border-color: #17a2b8;
}

a:hover {
	cursor: pointer;
}
.kbheight{
	height: 70vh;
}

#Kbarticlecssmainclick{
	color: #0dcaf0!important;
    cursor: pointer;
}