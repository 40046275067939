

.react-bootstrap-table table {
    table-layout: fixed !important;
    border: 1px solid rgb(234, 233, 233)!important;
    background-color: white!important;
  
}

.react-bootstrap-table table tr td{
    border: 1px solid rgb(234, 233, 233)!important;
}

.react-bootstrap-table table {
    table-layout: auto !important;
}
.PreviousProcedureheight{
    height: 60vh;
}
.toastclass{
    background-color: azure;
}