.heading-section3{
  background-color: #17a2b8!important;
  border-radius: 50px;
}
.required:after { content:" *"; color : red }

.viewindetail{
  color: #17a2b8;
  cursor: pointer;
}
.nav-link{
  color:#172f43 ;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {

  background-color: #17a2b8!important;
  color: black!important;
}

.custom-class {
  background-color: #172f43  !important;
  color: white!important;
}

.active a{
  background-color: #17a2b8;
  color: #fff;
}
tbody, td, tfoot, th, thead, tr {
  border-style: groove !important;
}

/* PROFILE SMALL SCREEN CSS
.TopCss {
  width: 81%;
  margin: 2rem auto;
}

.navCSS {
  width: 100%;
  height: 4rem;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  position: relative;
  overflow: auto;
}

.navCSS::after {
  position: absolute;
  width: 100%;
}

.liststyleMedical {
  float: left;
  color: #77bcf7 !important;
  text-align: center;
  cursor: pointer;
  transition: .1s ease-in-out;
  float: none;
  padding: 23px;
  list-style: none;
}

.liststyleMedical:hover {
  color: #232F3E;
  background-color: #aad5fa;
  content: '';
  height: 8rem;
  padding: 8px;
  border-radius: 5%;
  transition: .1s ease-in-out;

}

#labelCssMedical{
  color:black;
  font-family: sans-serif;
  font-weight: 600;
} */

.react-bootstrap-table table {
  table-layout: auto !important;
}