
.bgHeader{
    background-color: #100e1012 ;
}

#btnsubmitlocked{
    background-color: #172f43  !important;
}

#logoshow{
    display: none;
  }