.react-bootstrap-table table {
    table-layout: auto !important;
}

.custom-class {
    background-color: #172f43 !important;
    color: white !important;
}

.viewindetail{
    color: #17a2b8 !important;
    cursor: pointer !important;
}
.Treatmentheight{
    height: 60vh;
}