.UpdateDR:hover { 
  background: #17a2b8!important;
} 


.switch {
  position: relative;
  display: inline-block;
  width: 115px;
  height: 38px;
}

.switch input {display:none;}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(173, 170, 170)!important;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color:white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #172f43!important;
}

input:focus + .slider {
  box-shadow: 0 0 1px #c6c9c9 !important;
   
}

input:checked + .slider:before {
  -webkit-transform: translateX(55px);
  -ms-transform: translateX(55px);
  transform: translateX(55px);
}

/*------ ADDED CSS ---------*/
.on
{
  display: none;
}

.off
{
  color: white;
  position: absolute;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 75%;
  font-size: 15px;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}
.on
{
  color: white;
  position: absolute;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 34%;
  font-size: 15px;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

input:checked+ .slider .on
{display: block;}

input:checked + .slider .off
{display: none;}

/*--------- END --------*/

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;}
  .round{
      border-radius:50px!important;
    }
 
    
    .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
      background-color: #17a2b8!important;
      color: #eee!important;
  }
  .nav-item:hover{
    background-color: #f2f4f9;
    color: #17a2b8 !important;
  }
  .react-bootstrap-table table {
    table-layout: auto !important;
}