.PreviousFertiliyHeight{
    height: 60vh;
}
.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0;
  }
  
  .modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform .3s ease-out;
    -o-transition: -o-transform .3s ease-out;
    transition: transform .3s ease-out;
    -webkit-transform: translate(0, -25%);
    -ms-transform: translate(0, -25%);
    -o-transform: translate(0, -25%);
    transform: translate(0, -25%)
  }
  
  .modal.in .modal-dialog {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0)
  }
  
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: hidden;
  }
  
  .modal-dialog {
    position: relative;
    width: auto;
    margin: 10px
  }
  
  .modal-content {
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #999;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
  }
  
  .modal-header {
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
    background-color: #F7F8FA;
  }
  
  .modal-header .close {
    margin-top: 5px
  }
  
  .modal-header .back {
    margin-top: 5px
  }
  
  .modal-title {
    margin: 0;
    line-height: 1.42857143
  }
  
  .modal-body {
    position: relative;
    padding: 15px;
    height: 350px!important;
    overflow-y: auto;
    overflow-x: hidden;
  }
  
  .modal-footer1 {
    padding: 15px;
    border-top: 1px solid #e5e5e5;
    background-color: #F7F8FA;
  }
  
  @media(max-width:767px) {
    #step-modal {
      padding: 0px !important;
    }
    .modal-dialog {
      margin: 0px;
    }
  }