code {
    
      font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
  }
  
  
  .box {
    width: -webkit-fill-available;
  }
  .box .steps {
    border-bottom: 1px solid #ddd;
  }
  
  .box .steps ul {
    overflow: hidden;
  }
  
  .box .steps ul li div {
    color: #999;
    padding: 15px 0 20px 0px;
    font-size: 15px;
    position: relative;
    background: #f5f5f5;
    flex: 1 0;
  
  }
  .box .steps ul li div span {
    font-size: 13px;
    font-weight: bold;
  }
  
  .box .steps ul li:first-child div {
    padding-left: 0px;
  }
  
  
  .box .steps ul li.done div {
    border-color: #20a8d8 !important;
    color: #fff !important;
    background: #20a8d8 !important;
    flex: 1 0;
  }
  
  .box .steps ul li.done div::after {
    border-left: 30px solid #20a8d8;
    flex: 1 0;
  }
  
  .box .steps ul li.active div {
    border-color: #167495 !important;
    color: #fff !important;
    background: #167495 !important;
  }
  
  
  .box .step-component {
    padding: 0px;
    height: 00px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
   .box .btn-component {
    padding: 20px;
    display: flex;
    justify-content: space-between;
  }
  
  .form-check-input:checked {
    background-color: #20a8d8 !important;
    border-color: #20a8d8 !important;
  }
  
  .accordion-button {
    position: relative;
    align-items: center;
    width: 100%;
    padding: 1rem 1.25rem;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    background-color: #e9ecef;
  }
  
  .ScrollDocumnentdata{
    border: 1px solid #8080802b;
    width: 100%;
    padding: 10px;
  }
  
  .docCss{
    font-size: 18px; font-weight: 700;
  }
  
  .dropzone {
    height: 200px;
    display: table;
    width: 100%;
    background-color: #eee;
    border: dotted 1px #aaa;
  }
  
  .list-group-item.active {
      z-index: 2;
      color: #fff;
      background-color: #0079bc;
      border-color: #17a2b8 !important;
  }
  
  .slider.round {
    border-radius: 34px;
    width: 77%;
    height: 88%;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  .card-header:not(.collapsed) .rotate-icon {
    transform: rotate(180deg);
  }
  .form-control-one {
    background: #fff !important;
    color: #000000 !important;
    font-size: 12px;
    border-radius: 15px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 1px solid #ADC1D0;
  }
  .file {
   width:100%;
    padding: 140px 50px 10px 50px;
    cursor: move;
    position:relative;
    min-height: 150px;
    border: 2px solid rgba(0, 0, 0, 0.3);
    background: white;  
  }
  .file:before {
    content: "drag & drop your HL7 files here";
    display: block;
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    width: 200px;
    height: 40px;
    margin: -25px 0 0 -100px;
    font-size: 18px;
    font-weight: bold;
    color: #999;
  }
  .file::after {
    content: "drag & drop your HL7 files here";
    display: block;
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    width: 200px;
    height: 40px;
    margin: -25px 0 0 -100px;
    font-size: 18px;
    font-weight: bold;
    color: black;
  }
  .filetwo:before {
    content: "drag & drop your file here";
    display: block;
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    width: 200px;
    height: 40px;
    margin: -25px 0 0 -100px;
    font-size: 18px;
    font-weight: bold;
    color: #999;
  }
  .filetwo {
   
    padding: 1% 65% 3% 6.8%;
    cursor: move;
    position:relative;
    border: 2px solid rgba(0, 0, 0, 0.3);
    background: white;  
    
  }
  .filetwo::after {
    content: "drag & drop your file here";
    display: block;
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    width: 200px;
    height: 40px;
    margin: -25px 0 0 -100px;
    font-size: 18px;
    font-weight: bold;
    color: black;
  }
  .f-16{
    font-size:16px;
  }
  .docCss{
    padding: 0% 2% 0% 2%;
  }
  .btnColor{
    background-color: #aaa;
  }
  .docCssOne{
    background-color:#0872af;color:white;height:35px; width: 100%;margin: auto;
  }
  .breadcrumb{
    border-radius: 0%;
  }
  #modal-header{
    background-color:#2196f3c9 !important;
  }
  .ScrollDocumnentdata{
    border: 1px solid #8080802b;
    width: 100%;
    padding: 10px;
  }
  .card-body{
    border:none;
  }
  .divCenter{
    font-size:small;width: 60%;margin: 0 auto;float: none;
  }

 