
.clearfix:after {
    clear: both;
    content: "";
    display: block;
    height: 0;
    
}

.wrapper {
  display: table-cell;
  height: 00px;
  vertical-align: middle;
}

/* .nav {
  margin-top: 40px;
} */

.pull-right {
  float: right;
}

.active {
  color: #0088ff !important;
  text-decoration: none;
}

a:hover {
  color: #999;
}
/* .arrow-steps{
  border-style: ridge;
} */

/* Breadcrups CSS */

.arrow-steps .step {
  font-size: 15px;
  text-align: "center";
  color: #fff;
  margin: 0 3px;
  padding: 5px 10px 5px 40px;
  min-width: 182px;
  float: left;
  position: relative;
  background-color: #172f43;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; 
  transition: background-color 0.2s ease;
  border-bottom-style: solid;
}

/* .arrow-steps .step:after,
.arrow-steps .step:before {
  content: " ";
  position: absolute;
  top: 0;
  right: -17px;
  width: 0;
  height: 0;
  border-top: 21px solid transparent;
  border-bottom: 23px solid transparent;
  border-left: 17px solid #172f43;  
  z-index: 2;
  transition: border-color 0.2s ease;
} */

.arrow-steps .step:before {
  right: auto;
  left: 0;
  border-left: 17px solid #f5f5f5; 
  z-index: 0;
}

.arrow-steps .step:first-child:before {
  border: none;

}

.arrow-steps .step:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.arrow-steps .step span {
  position: relative;
}

.arrow-steps .step span:before {
  opacity: 0;
  content: "✔";
  position: absolute;
  top: -3px;
  left: -20px;
 
}

.arrow-steps .step.done span:before {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease 0.5s;
  -moz-transition: opacity 0.3s ease 0.5s;
  -ms-transition: opacity 0.3s ease 0.5s;
  transition: opacity 0.3s ease 0.5s;
}

.arrow-steps .step.current {
color: #fff;
background-color: #788f97;

}

.arrow-steps .step.current:after {
border-left: 17px solid #788f97;  
} 