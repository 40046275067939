.heading-section3{
    background-color: #17a2b8!important;
    border-radius: 50px;
    }
    .required:after { content:" *"; color : red }
    
 
        table.no-spacing {
        border-spacing:100;  /*Removes the cell spacing via CSS  */
        border-collapse: collapse; /* Optional - if you don't want to have double border where cells touch */
        }

        .fa{
            cursor: pointer;
        }

        #cardabovetable{
            border: 0px solid !important;
        }


         #cardbodycss{
            background-color: rgb(240, 243, 248);
            border: 1px solid rgb(173, 193, 208);
            border-radius: 10px;
        }

        .col-lg-4{
            line-height: 1.3!important;
        }

        #headingofdetails{
            color: #17a2b8;
            font-weight: 500;
        }

        /* #IdToBeAppent{
            height: 610px;
            overflow-y: scroll;
        } */

        @media (max-width: 1440px) and (min-width: 1280px){
            #IdToBeAppent{
                height: 410px!important;
            }
        }

        .required-asterisk{
            color: red;
            font-weight: bold;
            margin-left: 3px;
            font-size: 1.2em;
        }

        #canelpopupmodalbtn{
            border: 1px solid grey !important;
        }

        #chartContainer12{
            height: 700px; 
            width: 100%;
            margin-top: 1%;
        }

        #chartContainer{
            height: 395px; 
            width: 100%;
            margin-top: 1%;
        }


        .react-bootstrap-table table {

            table-layout: auto !important;
          
          }

          @media (max-width: 766px){
            .updatetop{
                margin-top: 12px!important;
            }
        }