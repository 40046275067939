.react-bootstrap-table table {
    table-layout: auto !important;
}

.dropdown-menu {
    background-color: white !important;

}

.dropdown-menu .dropdown-item {
    color: black !important;
}

.react-bootstrap-table table {
    table-layout: auto !important;
}
.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    /* background-color: #0492C2; */
    /* border-color: #0492C2; */
    background-color: #172f43;
    border-color: #172f43;
  }
 
   .dropdown-item a {
    color:black !important;
  
    
  }
  