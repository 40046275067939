@import url("https://fonts.googleapis.com/css?family=Lato");
* {
  box-sizing: border-box;
}
html,
body {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace ;
  margin: 0;
  min-height: 100vh;
  padding: 0;
}
html:before,
body:before {
  bottom: 0;
  /* content: ''; */
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
}
svg {
  cursor: pointer;
  height: 44px;
  width: 100px;
}
svg path {
  fill: #fff;
}
ol {
  list-style-type: none;
}
a[href] {
  color: #fff;
  position: relative;
  text-decoration: none;
}
a[href]:hover:after {
  transform: scaleX(1);
}
a[href]:after {
  content: '';
  position: absolute;
  top: 100%;
  height: 4px;
  background: #fff;
  left: 0;
  right: 0;
  transition: transform 0.15s;
  transform-origin: left;
  transform: scaleX(0);
}
.menu {
  position: relative;
  width: 250px;
}
.menu__content {
  color: #fff;
  margin: 0;
  padding: 0 0 25px 0;
  position: absolute;
  right: 100%;
  top: 0;
  width: 250px;
  z-index: 2;
}
.menu__toggle-label {
  height: 44px;
  left: 0;
  position: absolute;
  width: 44px;
}
.menu__toggle-label svg {
  left: 0;
  position: absolute;
  top: 0;
  transition: transform 0.15s;
  z-index: 2;
}
.menu__toggle-label svg:nth-of-type(2) {
  left: 250px;
  transform: scale(0);
}
.menu__toggle {
  opacity: 0;
  position: fixed;
}
.menu__toggle:checked ~ .menu__toggle-label {
  background: rgba(0, 0, 0, 0.65);
  height: 90vh;
  left: 0;
  position: fixed;
  top: 0;
  transition:  0.20s;
  width: 90vw;
}
.menu__toggle:checked ~ .menu__toggle-label svg:nth-of-type(1) {
  transform: scale(0);
}
.menu__toggle:checked ~ .menu__toggle-label svg:nth-of-type(2) {
  left: 250px;
  transform: scale(1);
  transition: transform 0.15s;
  transition-delay: 0.925s;
}
.menu__toggle:checked ~ .menu__content {
  transform: translate(100%, 0);
}
.menu__toggle:checked ~ .menu__content .menu-item {
  transform: translateX(0);
  transition: transform 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.275), border-color 0.15s;
}
.menu__toggle:checked ~ .menu__content .menu-item:nth-of-type(1) {
  border-color: #fdfcfd;
}
.menu__toggle:checked ~ .menu__content .menu-item:nth-of-type(1) a[href]:after {
  background: #fdfcfd;
}
.menu__toggle:checked ~ .menu__content .menu-item:nth-of-type(2) {
  border-color: #fdfcfd;
}
.menu__toggle:checked ~ .menu__content .menu-item:nth-of-type(2) a[href]:after {
  background: #fdfcfd;
}
.menu__toggle:checked ~ .menu__content .menu-item:nth-of-type(3) {
  border-color: #fdfcfd;
}
.menu__toggle:checked ~ .menu__content .menu-item:nth-of-type(3) a[href]:after {
  background: #fdfcfd;
}
.menu__toggle:checked ~ .menu__content .menu-item:nth-of-type(4) {
  border-color: #fdfcfd;
}
.menu__toggle:checked ~ .menu__content .menu-item:nth-of-type(4) a[href]:after {
  background: #fdfcfd;
}
.menu__toggle:checked ~ .menu__content .menu-item:nth-of-type(5) {
  border-color: #fdfcfd;
}
.menu__toggle:checked ~ .menu__content .menu-item:nth-of-type(5) a[href]:after {
  background: #fdfcfd;
}
.menu__toggle:checked ~ .menu__content .menu-item:nth-of-type(1) {
  transition-delay: 0.225s, 0.875s;
}
.menu__toggle:checked ~ .menu__content .menu-item:nth-of-type(2) {
  transition-delay: 0.275s, 0.875s;
}
.menu__toggle:checked ~ .menu__content .menu-item:nth-of-type(3) {
  transition-delay: 0.325s, 0.875s;
}
.menu__toggle:checked ~ .menu__content .menu-item:nth-of-type(3) .menu-item:nth-of-type(1) {
  transition-delay: 0.375s, 0.875s;
}
.menu__toggle:checked ~ .menu__content .menu-item:nth-of-type(3) .menu-item:nth-of-type(2) {
  transition-delay: 0.425s, 0.875s;
}
.menu__toggle:checked ~ .menu__content .menu-item:nth-of-type(3) .menu-item:nth-of-type(3) {
  transition-delay: 0.475s, 0.875s;
}
.menu__toggle:checked ~ .menu__content .menu-item:nth-of-type(4) {
  transition-delay: 0.525s, 0.875s;
}
.menu__toggle:checked ~ .menu__content .menu-item:nth-of-type(4) .menu-item:nth-of-type(1) {
  transition-delay: 0.575s, 0.875s;
}
.menu__toggle:checked ~ .menu__content .menu-item:nth-of-type(4) .menu-item:nth-of-type(2) {
  transition-delay: 0.625s, 0.875s;
}
.menu__toggle:checked ~ .menu__content .menu-item:nth-of-type(4) .menu-item:nth-of-type(3) {
  transition-delay: 0.675s, 0.875s;
}
.menu__toggle:checked ~ .menu__content .menu-item:nth-of-type(5) {
  transition-delay: 0.725s, 0.875s;
}
.menu__content > .menu-item {
  border-left: 8px solid transparent;
}
.menu__content > .menu-item > a {
  line-height: 44px;
  min-width: 60px;
}
.sub-menu {
  padding: 0 0 0 44px;
}
.menu-item {
  line-height: 44px;
  min-height: 44px;
  padding: 0 12px;
  transform: translateX(-100%);
}
.menu-item .menu-item {
  transform: translateX(-150%);
}
