/* .phonesymbol .react-tel-input .form-control{
    border:none !important  ;
    border-radius: none ;
    width: auto;
    height:20px !important; 
  
  }
  react-tel-input .form-control {
    padding-left: 0% !important ;
  } */

  .phonesymbol .react-tel-input .flag-dropdown{
    /* top: 6px !important ;  */
    border:none !important ;
    height: 20px;
    position:absolute; 
    border: none;
    background-color: white;
  }
  .phonesymbol .react-tel-input .selected-flag .flag {
    outline: none !important ;
    position: relative !important ;
    padding: 0 0 0 0px !important ;
    border-radius: 3px 0 0 3px !important ;
    
}
#floatingInputGrid10{
  height: calc(2.5rem + 2px) !important;
}
.form-select:disabled {
  background-color: #fff !important;
}
.form-control:disabled {
  background-color: #fff !important;
  opacity: 1;
}
.disAble {
  cursor: not-allowed;

}
