
body {
    background-color: #0088ff;
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace ;
  }
  
  #container {
    position: relative;
    background-color: #232F3E;
    /* background-color: rgb(28, 45, 60); */
    /* background-color:  rgb(55, 65, 81); */
  
    
  }
  
  #container>nav {
  
    display: flex;
    max-height: 600px;
  }
  
  #logo {
    line-height: 80px;
    font-size: 30px;
    /* padding: 0 50px; */
    color: white;
    width: 40%;
    letter-spacing: 2px;

  }
  
  #container>nav>ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    position: absolute;
    right: 0px;
    min-width: 28%;
    margin-right: 6%;
  }
  
  #container>nav>ul>li {
    line-height: 60px;
    padding: 0;
    display: inline-block;
    font-size: 22px;
    text-align: left;
    margin-top: 10px;
  margin-left: 20px;
  
  }
  
  #container>nav>ul>li>a {
    position: relative;
    margin: 0 10px;
    color: white;
  }
  
  #container>nav>ul>li>i {
    margin-left: 0 2px;
    padding-left: 10px;
  }
  #container>nav>ul>li>i:hover{
    color: white;
  }
  
  #container>nav>ul>li:hover {
    border-bottom: solid 7ebaef;
    background-color: #4ca7f6;
    color: white !important;
  
  }
  
  #container>nav>ul>.dropdown:hover {
    border-bottom: none;
  
  }
  
  #container>nav>ul>.dropdown:hover>.dd {
    display: block;
  }
  
  .dropdown:hover>a .fa-caret-down {
    display: none;
  }
  
  a {
    text-decoration: none;
  
  }
  
  #container>nav>ul>li>div {
    position: absolute;
    padding: 0 10px;
    display: none;
    background-color: rgb(28, 45, 60);
    min-width: 140px;
  }
  
  #container>nav>ul>li>div>ul {
    margin: 0px 0 10px 0;
    padding: 0;
    list-style: none;
  
  }
  
  #u_a_c {
    position: relative;
    width: 100%;
  }
  
  
  #container>nav>ul>li>div>ul>li>a {
    padding: 6px;
    color: white;
  }
  
  /* for dropwdon to not overlap */
  .dropdown{
    z-index: 999;
  }
  .fontheader{
    font-size: 14px;
   font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
  
  }
  a{
    text-decoration: none !important;
  }
  
  /* header  css Oxar old Portal */ 
  
  @media(max-width:800px) {
    #CLinicLogo12{
      width: 50% !important;
    }
  }
  
  #CLinicLogo12{
    width: 45%;
  }