@import url(https://fonts.googleapis.com/css?family=Dosis:600,200|Great+Vibes);


[class="fa"]:before {
  font-family: 'fontawesome', sans-serif;
}



.TopCss {
  /* width: 90%; */
  margin: 2rem auto;
}
.editProfileHeader {
  /* background-image: linear-gradient(#77bcf7, #eff4fb); */
  background-image: linear-gradient(white, #c5ddf5);
}
.navCSS {
  /* width: 100%; */
  height: 8rem;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  position: relative;
  overflow: auto;
}

.navCSS::after {
  position: absolute;
  width: 100%;
}

#liststyle {
  float: left;
  color: #172f43 !important;
  text-align: center;
  cursor: pointer;
  transition: .5s ease;
  float: none;
  padding: 7px;
  list-style: none;
}


#liststyle a {
  color: #172f43;
  /* font-family: sans-serif; */
}

#liststyle:before {
  /* display: block; */
  font-size: 2rem;
  /* line-height: 4rem; */
}


.smallCsslabel {
  color: white;
  height: 80%;
  margin: 1px;
  box-shadow: 0 0 7px #1f2b3b(0 0 0 / 50%);
  border-radius: 6%;
  background-color: #1f2b3b;
  font-size: 1rem;
  width: 15%;
  align-items: center;
  text-align: center;
  float: left;
  cursor: pointer;
  list-style: none;
}
.smallCsslabel a{
  color: white !important;
  height: 50%;
  margin: 1px;
  box-shadow: 0 0 7px rgba(0, 0, 0, .5);
  border-radius: 5%;
  font-size: 1rem;
  list-style: none;
}
.smallCsslabel:before {
  font-size: 2rem;
  color: white !important;
}
.smallCsslabel:after {
  font-size: 2rem;
  color: white !important;
}


@media (max-width: 375px) {
  .smallCsslabel {
    width:30%;
  }
}

@media (max-width: 414px) {
  .smallCsslabel {
    width:40%;
  }
}

/* --------------------------------------- Big  SCREEN CSS  ---------------------------------------------------- */


@import url(https://fonts.googleapis.com/css?family=Dosis:600,200|Great+Vibes);

.TopSmallCss {
  width: 80%;
  margin: auto;
}

.fontProfile {
  font-size: 15px;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
  font-weight: normal;
}

.bigNavCSS {
  width: 100%;
  height: 7rem;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  position: relative;
}

.bigCSSlabel {
  color: white;
  height: 80%;
  margin: 1px;
  box-shadow: 0 0 7px #1f2b3b(0 0 0 / 50%);
  border-radius: 6%;
  background-color: #1f2b3b;
  font-size: 1rem;
  width: 10%;
  align-items: center;
  text-align: center;
  padding-top: 5px;
}




.bigCSSlabel a {
  color: #ecf1f8 !important;
  height: 50%;
  margin: 1px;
  box-shadow: 0 0 7px rgba(0, 0, 0, .5);
  border-radius: 5%;
  font-size: 1rem;
}


.bigCSSlabel:before {
  font-size: 3rem;
  color: white !important;
}

.bigCSSlabelbefore {
  height: 68%;
  margin: 1px;
  box-shadow: 0 0 7px #1f2b3b(0 0 0 / 50%);
  border-radius: 6%;
  background-color: #1f2b3b;
  font-size: 1rem;
  width: 10%;
  align-items: center;
  text-align: center;
}

#bigCSSlabelactive {
  color: black !important;
  width: 10%;
  float: left;
  color: #172f43;
  text-align: center;
  cursor: pointer;
  transition: .3s ease;
  font-size: 2rem;
  margin-top: 15px
}

#bigCSSlabelactive a {
  color: black
}



.editProfileHeader {
  /* background-image: linear-gradient(#77bcf7, #eff4fb); */
  background-image: linear-gradient(white, #c5ddf5);
}

/* Css for image upload */
.custom-file-upload {
  border-radius: 50%;
  /* display: inline-block; */
  position: relative;
  /* padding: 6px; */
  cursor: pointer;
  /* background: linear-gradient(270deg, #3fa1a9, #0492C2); */
  margin-bottom: 25px;
}

.img-wrap {
  position: relative;
  /* width: 150px;
    height: 150px; */
  overflow: hidden;
  border-radius: 50%;
}

.img-upload:before {
  content: "\f093";
  font-size: 90px;
  position: absolute;
  padding-top: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  /* color: #0492C2; */
  width: 150px;
  height: 150px;
  border-radius: 50%;
  opacity: 0;
  transition: .5s ease;
  background-color: #fff;
}

.img-upload:hover:before {
  opacity: 1;
}

.card.hovercard .avatar img {
  width: 100px;
  height: 100px;
  max-width: 100px;
  max-height: 100px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  /* border: 10px solid rgba(255,255,255,0.5); */
}

img {
  width: auto;
  height: 100%;
}

input {
  border-radius: 15px;
  border: 1px solid #b7b7b7;
  padding: 5px 5px 5px 10px;
  font-size: 18px;
  transition: 0.2s ease;
}

input:focus {
  outline: none;
  border: 1px solid #0492C2;
}

input::placeholder {
  color: #bebebe;
}

@media (max-width: 710px) {
  .smallCsslabel {
    width:50%;
  }
}

.react-tel-input .form-control {
  height: 20px !important;
}

