.react-bootstrap-table table {
    table-layout: auto !important;
}

/* .page-item .page-link {
    background: blue;
  } */
  .pagination > .active > a:hover
  {
      /* background-color: #5A4181 !Important;
      border: solid 1px #5A4181; */
      background-color: #172f43 !Important;
      border: solid 1px #172f43;
      
  }