.columnmsg {
    float: left;
    width: 50%;
    padding: 10px;
  }
  .incoming_msg{
    padding-top: 10px !important;
}
.file-image{
    /* width:84px;
    height: 76px; */
    width:46px;
    height: 35px;
    border-radius: 50%;
    background-blend-mode: lighten;
}
.custome_msg-scroll::-webkit-scrollbar {
    width: 3px;
}
.comments-list .media {
    border-bottom: 1px dotted #ccc;
}
/* .media-body{
    background-color: aliceblue;
    
} */
.custome_msg-scroll {
    height: 450px;
   overflow-y: scroll; 
}
.fssmall{
    font-size: 14px;
    font-weight: bolder;
    color: #009FE3;
}
.messageui{
  color: white;
  background: #3f5b83;
  border-radius:23px 23px 1px 23px;
  padding: 5px;
}

