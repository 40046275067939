


/* Container holding the image and the text */
.imageforbg {
    height: 500px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
}  

  
  .top-left {
    position: absolute;
    top: 25%;
    left: 37%;
    color: white;
    font-size: 50px;
  }

 .centered {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* background-color: #000; */
    color: #fff;
    opacity: .8;
  }

  .btn1 {
    
    background-color: #fff;
    border: none;
    color: #323D86;
    padding: 6px 87px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    font-weight: 600;
    cursor: pointer;
    text-transform: uppercase;
  }
  .btn2 {
    
    background-color: #fff;
    border: none;
    color: #323D86;
    padding: 6px 77px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    font-weight: 600;
    cursor: pointer;
    text-transform: uppercase;
  } 

/* oxar home page */
/* @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .animate {
    min-height: 110px;
  }

  .animate1 {
    min-height: 110px;
    padding: 2%;
  }

  .headingsection {
    height: 500px;
  }

  .card_test {
    transition: 0.3s;
    margin-top: 9%;
    width: 180%;
  }

  .margin {
    margin-top: 4%;
  }

  .col-md-4 {
    width: 25% !important;
  }

  .containerpadding {
    padding: 120px 16px;
  }

  .containerpadding1 {
    padding: 40px 16px;
  }

  .paddingcontainer {
    padding: 185px 16px;
  }
}

.hcolor {
  font-family: sans-serif;
  color: #fff;
  font-size: 50px;

}

@media (min-width: 576px) {
  .jumbotron {
    padding: 8rem 0rem !important;
  }

}

@media (max-width: 481px) {
  .card_test {

    transition: 0.3s;
  }

  .headingsection {
    height: 1000px;
  }

  .margin {
    margin-top: 8%;
  }

  .name {
    color: #000;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 600;
  }

  .name1 {
    font-size: 14px;
    color: #0c0c0c;

  }

  .name2 {
    font-size: 14px;
    text-transform: uppercase;
    color: #0c0c0c;

  }
}

@media (min-width: 781px) {
  .animate {
    min-height: 110px;
  }

  .animate1 {
    min-height: 110px;
    padding: 2%;
  }

  .headingsection {
    height: 500px;
  }

  .card_test {
    transition: 0.3s;
    margin-top: 9%;
    width: 180%;
  }

  .kbtitle {
    text-align: center;
    margin: 1% 0% 0% -3%;
  }

  .margin {
    margin-top: 4%;
  }

  .name {
    color: #000;
    font-size: 20px;
    margin: 0% 0% 0% -18%;
    font-weight: 600;
    text-transform: uppercase;
  }

  .name1 {
    font-size: 14px;
    color: #0c0c0c;
    margin: 0% 0% 0% -18%;
  }

  .name2 {
    font-size: 14px;
    text-transform: uppercase;
    color: #0c0c0c;
    margin: 0% 0% 0% -18%;


  }

  .displayname {
    margin: 4% 0% 0% -18%;
  }

  .container_padding {
    padding: 40px 16px;
  }

  .row_margin {
    margin: 0% 14% 3% 14%;
  }

  .containerpadding {
    padding: 120px 16px;
  }

  .containerpadding1 {
    padding: 40px 16px;
  }

  .paddingcontainer {
    padding: 185px 16px;
  }
}

#demo {
  height: auto;
}


.breadcam_bg {
  background-size: cover;
  color: white;
  background-image: url('../public/assets/img/oxar.png');
  height: 500px;
}

.btn-custom {
  background-color: #fff;
  border: none;
  color: #323D86;
  padding: 6px 87px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  font-weight: 600;
  cursor: pointer;
  text-transform: uppercase;
}

body {
  font-family: sans-serif;
}

.h2color {
  color: white;
  font-family: sans-serif;
  font-size: 30px;
}


#mastheadTitle,
#typedElement {
  font-family: 'Yantramanav', sans-serif;
}

#mastheadTitle {
  line-height: 1.1;
}

#typedElement {
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 30px;
  line-height: 1.2;
}

@media (max-width: 640px) {
  #mastheadTitle {
    font-size: 40px;
  }

  #typedElement {
    font-size: 30px;
  }
}

@media (min-width: 1900px) {
  .breadcam_bg {
    height: 575px;
  }
}

@media (width: 1440px) {
  .breadcam_bg {
    height: 450px;
  }
} */
@media (max-width: 640px){ 
  .imageforbg {
    height: 60vh;
  }
}

@media (min-width: 1900px) {
.imageforbg {
  height: 575px;
}
}

@media (width: 1440px) {

.imageforbg {
  height: 450px;
}
}
.test{
  display: grid !important;
  justify-content: center !important;
}
@media (min-width: 768px) and (max-width: 1024px){
  .centered{
    top: 25%;
  }
}

/* .NEwImage{
  height: 350px !important;
} */

#anothersectionSignIn{
  background-color: #4C6FE8 ;
}


@media (max-width: 425px){ 
  .navbar1 {
    height: 90px !important;
  }
}


