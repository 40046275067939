.pincodefilter{
   margin-top: 10vh;
   margin-bottom: 10vh;;
    background-color: #ededed;
    box-shadow: 0 15px 25px rgba(73, 73, 73, 0.2);
    backdrop-filter: blur(20px);
    
}
#Colortext{
color: red !important;
}

@media only screen and (max-width: 600px) {
    input{
        height: auto !important;
    }
  }