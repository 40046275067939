body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
  font-size: 14px !important;
}

code {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace ;

}

.react-bootstrap-table table {
  table-layout: auto !important;
}

.form-control {
  font-size: 0.75 !important;
}

.form-select {
  font-size: 0.75 !important;
}

.btnclass {
  background-color: #172f43 !important;
  border: color #172f43 !important;
  color: white !important;
  font-size: small!important;
  outline: none;
}

/* address lookup css */
.location-search-input,
.location-search-input:focus,
.location-search-input:active {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  border: honeydew;
  display: block;
  width: 100%;
  padding: 16px;
  font-size: 16px;
  border-radius: 2px;
  outline: none;
}

.autocomplete-dropdown-container {
  border-bottom: honeydew;
  border-left: honeydew;
  border-right: honeydew;
  border-top: 1px solid #e6e6e6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: absolute !important;
  z-index: 3 !important;
  border-radius: 0 0 2px 2px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0492C2;
  border-color: #0492C2;
}

.page-link {
  color: black !important;
}

.dropdown-menu {
  background-color: white !important;

}

.dropdown-menu .dropdown-item {
  color: black !important;
}

.react-bootstrap-table table {
  table-layout: auto !important;
}

/* card color */
.cardcolor {
  background-color: #d1b4ca08 !important;
}

.react-tel-input {

  font-size: 0.9rem !important;

}
li{
  cursor: pointer !important;
}


.react-tel-input .flag-dropdown {

  top: 6px !important;

  border: none !important;
  height: 10px;

  position: absolute;

  margin-left: auto;

  /* margin-left: 4px; */

  border: none;

  background-color: white;

}
 /* .react-tel-input .flag-dropdown{
  top: 6px !important ; 
  border:none !important ;
  height: 20px;
  position:absolute; 
  margin-left: 4px;
  border: none;
  background-color: white;
  outline: none!important ;
} */



.react-tel-input .form-control {

  margin-top: 0 !important;
  margin-bottom: 0 !important;
  position: relative;
  letter-spacing: .01rem;
  border-radius: 0 !important;
  width: 80% !important;
  font-size: 0.9rem !important;
  border: none !important;
  border-radius: none;
  width: auto;
  margin-left: 25px !important;
  height: 10px !important;

}

.toast-message {
  background: #50C878!important;
  font-weight: bold!important;
  color: #fff!important;
}

.toast-warn {
  background: #eed202 !important;
  font-weight: bold !important;
  color:white !important;
}
